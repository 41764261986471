import dayjs from "dayjs";
import React, { useState, useEffect, useMemo } from "react";
import { useSocketContext } from "~/client/socket/hooks/use-socket-context";
import { LobbyEvent } from "~/shared/lobby/types";
import { Timeleft } from "./styled";
export const Countdown = ({ limit, current }) => {
    const [tick, setTick] = useState(0);
    const socket = useSocketContext();
    const date = useMemo(() => dayjs().hour(0).minute(0), []);
    useEffect(() => {
        socket.on(LobbyEvent.UpdateTimeout, setTick);
        return () => {
            socket.off(LobbyEvent.UpdateTimeout, setTick);
        };
    }, []);
    return tick > 0 && (React.createElement(Timeleft, { danger: current && tick <= Math.round(limit / 3) }, date.second(tick).format("mm:ss")));
};
