import React, { useState, useEffect, useMemo, useRef } from "react";
import { WorldContext } from "./context";
import { Entity } from "./entity";
import { useSocketContext } from "~/client/socket/hooks/use-socket-context";
import { LobbyEvent } from "~/shared/lobby/types";
import { Field, Line } from "./styled";
export const World = ({ players }) => {
    const [world, setWorld] = useState();
    const [step, setStep] = useState();
    const socket = useSocketContext();
    const refWorld = useRef(null);
    const current = useMemo(() => (players.find((player) => player.id === socket.id)), [players]);
    const putEntity = (x, y) => {
        socket.emit(LobbyEvent.PutEntity, [x, y]);
    };
    useEffect(() => {
        socket.on(LobbyEvent.UpdateStep, setStep);
        socket.on(LobbyEvent.UpdateWorldMap, setWorld);
        return () => {
            socket.off(LobbyEvent.UpdateStep, setStep);
            socket.off(LobbyEvent.UpdateWorldMap, setWorld);
        };
    }, []);
    return world ? (React.createElement(Field, { ref: refWorld },
        React.createElement(WorldContext.Provider, { value: world }, world.map((line, y) => (React.createElement(Line, { key: y }, line.map((entity, x) => (React.createElement(Entity, { key: `${x}-${y}`, data: entity, x: x, y: y, active: step === (current === null || current === void 0 ? void 0 : current.slot), onPut: () => putEntity(x, y) }))))))))) : null;
};
